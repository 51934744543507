/** @jsx jsx */
import { jsx, Box, Flex, Card } from "theme-ui";
import React, { useEffect, useState, useRef } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import SlideToggle from "react-slide-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import HFNlogo from "../../../static/imgs/donations-logo.png";
import SRCMLogo from "../../../static/imgs/srcm-logo.png";
import Seo from "../../components/seo";

const donationSrcm = [
  {
    title: "Donation for SRCM - Corpus Fund",
    titleShort: "Corpus Fund",
    desc: "Donation to Corpus Fund would form a part of the capital of the Organization.",
    donationPath: "donation-corpus-fund",
    donationId: "18",
    modalTitle: "Donation for Corpus Fund",
  },
  {
    title: "Donation for SRCM - General Fund",
    titleShort: "General Fund",
    desc: "Donation to General Fund would be used for the objectives of the Organization and Heartfulness.",
    donationPath: "donation-general-fund",
    donationId: "19",
    modalTitle: "Heartfulness General Fund",
  },
];

const pageContent = (id, modalTitle) => {
  const titletext = modalTitle || "Heartfulness General Fund";
  return `<gatsby_donation amount='0' btntext='Donate Now' donationid=${id} layoutstyle='' btnstyle='cursor: pointer;'
    projectid='' btnbg='#444' colortext='#000000' colorprimary='#444444c7'
    colorsecondary='#444' colorbackground='white' colormuted='#f6f6f6'
    colorhighlight='#efeffe' titletext='${titletext}' allowoverseas='false'/>`;
};

const PageContent = React.memo(PageContentNonMemoized);

const renderDonateBtn = (id, titletext) => {
  const modalTitle = titletext || "Heartfulness General Fund";
  return <PageContent pageContent={pageContent(id, modalTitle)} />;
};

const ScrmInIframe = () => {
  const headerRef = useRef();

  const [applySticky, setApplySticky] = useState(false);

  const handleHeaderSticky = () => {
    if (headerRef.current && window.pageYOffset > headerRef.current.offsetTop)
      setApplySticky(true);
    else setApplySticky(false);
  };

  useEffect(
    () => window.addEventListener("scroll", handleHeaderSticky),
    [applySticky]
  );

  const [isMobile, setIsMobile] = useState(false);

  const windowSizeTracker = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    windowSizeTracker();
  }, []);

  const navBarLinks = [
    { title: "Home", id: "index" },
    { title: "Meditation", id: "meditation" },
    { title: "Centres", id: "centres" },
    { title: "Activities", id: "activities" },
    { title: "About Us", id: "about-us" },
    { title: "Donate", id: "donations", className: "donate-btn" },
  ];

  const NavigateRouter = (item) => {
    let message = {
      title: item.title,
      url: `/${item.id}`,
    };
    if (item.id === "index") {
      message = {
        title: item.title,
        url: "/",
      };
      window.parent.postMessage(message, "*");
    }
    window.parent.postMessage(message, "*");
  };

  return (
    <>
      <Box
        ref={headerRef}
        sx={
          applySticky
            ? {
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                background: "#fff",
                zIndex: 1000,
                boxShadow: "0 2px 6px rgb(0 0 0 / 5%)",
              }
            : {
                position: "static",
                boxShadow: "0 2px 6px rgb(0 0 0 / 5%)",
              }
        }
      >
        <SlideToggle
          collapsed="true"
          render={({ toggle, setCollapsibleElement }) => (
            <div className="header-collapsible">
              <div
                sx={{
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 0px 2px 1px #00000029",
                  logo: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    img: {
                      maxWidth: "300px",
                    },
                  },
                  nav: {
                    flexGrow: "2",
                    ul: {
                      display: "flex",
                      padding: 0,
                      justifyContent: "flex-end",
                      listStyle: "none",
                      li: {
                        a: {
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: "18px",
                          textDecoration: "none",
                          textTransform: "capitalize",
                        },
                      },

                      "@media screen and (max-width: 998px)": {
                        display: "none !important",
                      },
                    },
                    mobNavIcon: {
                      display: "none",
                      "@media screen and (max-width: 998px)": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        position: "relative",
                        top: "14px",
                        fontSize: "23px",
                        color: "#bbbbbb",
                      },
                    },
                  },
                }}
              >
                <div
                  sx={{
                    width: "100%",
                    maxWidth: "1160px",
                    margin: "0 auto",
                    "@media only screen and (max-width: 767px)": {
                      width: "300px",
                    },
                    "@media only screen and (min-width: 480px) and (max-width: 767px)":
                      {
                        width: "420px",
                      },
                    "@media only screen and (min-width: 768px) and (max-width: 959px)":
                      {
                        width: "748px",
                      },
                    "@media only screen and (min-width: 960px) and (max-width: 1199px)":
                      {
                        width: "920px",
                      },
                    "@media only screen and (min-width: 1200px) and (max-width: 1400px)":
                      {
                        width: "1160px",
                      },
                  }}
                >
                  <div
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        img: {
                          maxWidth: "300px",
                        },
                      }}
                    >
                      <span to="/">
                        <img
                          src={SRCMLogo}
                          sx={{ width: applySticky ? "200px" : "250px" }}
                          alt=""
                        />
                      </span>
                    </div>
                    <nav>
                      <ul>
                        {navBarLinks.map((item) => {
                          if (!item.className) {
                            return (
                              <li>
                                <span
                                  className={item.className || "menu-links"}
                                  onClick={() => NavigateRouter(item)}
                                  onKeyDown={() => NavigateRouter(item)}
                                  tabIndex={0}
                                  role="button"
                                  id={item.id}
                                  activeStyle={{
                                    color: "#179bb2",
                                  }}
                                  sx={{
                                    color: "#777777",
                                    ":hover": { color: "#179bb2" },
                                    padding: "0px 20px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    fontWeight: "300",
                                  }}
                                >
                                  {item.title}
                                </span>
                              </li>
                            );
                          }
                          return (
                            <li>
                              <span
                                className={item.className || "menu-links"}
                                onClick={() => NavigateRouter(item)}
                                onKeyDown={() => NavigateRouter(item)}
                                tabIndex={0}
                                role="button"
                                id={item.id}
                                activeStyle={{
                                  color: "#179bb2",
                                }}
                                sx={{
                                  ...(!item.className && {
                                    color: "#777777",
                                    ":hover": { color: "#179bb2" },
                                    padding: "0px 20px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    fontWeight: "300",
                                  }),
                                  ...(item.className && {
                                    padding: " 8px 15px",
                                    borderRadius: "4px",
                                    backgroundColor: "#288bd0",
                                    color: "white",
                                  }),
                                }}
                              >
                                {item.title}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      <div
                        sx={{
                          display: "none",
                          "@media screen and (max-width: 998px)": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            position: "relative",
                            top: "14px",
                            fontSize: "23px",
                            color: "#bbbbbb",
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBars}
                          size="1x"
                          onClick={toggle}
                        />
                      </div>
                    </nav>
                  </div>
                </div>
              </div>

              <div
                sx={{
                  display: "none",
                  background: "#fcfcfc",
                  nav: {
                    ul: {
                      listStyle: "none",
                      margin: "16px 0 30px 0",
                      padding: "0",
                      li: {
                        a: {
                          fontSize: "13px",
                          display: "block",
                          borderBottom: "1px solid #f2f2f2",
                          padding: "12px 0 !important",
                          textDecoration: "none",
                          textTransform: "capitalize",
                        },
                      },
                    },
                  },
                  "@media screen and (max-width: 998px)": {
                    display: "block",
                  },
                }}
                ref={setCollapsibleElement}
              >
                <nav
                  sx={{
                    width: "100%",
                    maxWidth: "1160px",
                    margin: "0 auto",
                    "@media only screen and (max-width: 767px)": {
                      width: "300px",
                    },
                    "@media only screen and (min-width: 480px) and (max-width: 767px)":
                      {
                        width: "420px",
                      },
                    "@media only screen and (min-width: 768px) and (max-width: 959px)":
                      {
                        width: "748px",
                      },
                    "@media only screen and (min-width: 960px) and (max-width: 1199px)":
                      {
                        width: "920px",
                      },
                    "@media only screen and (min-width: 1200px) and (max-width: 1400px)":
                      {
                        width: "1160px",
                      },
                  }}
                >
                  <ul sx={{ listStyleType: "none", float: "right" }}>
                    {navBarLinks.map((item) => {
                      if (!item.className) {
                        return (
                          <li
                            key={item.id}
                            sx={{
                              marginBottom: "10px",
                              borderBottom: "1px solid #f2f2f2",
                            }}
                          >
                            <span
                              className={item.className || "menu-links"}
                              onClick={() => NavigateRouter(item)}
                              onKeyDown={() => NavigateRouter(item)}
                              tabIndex={0}
                              role="button"
                              id={item.id}
                              activeStyle={{
                                color: "#179bb2",
                              }}
                              sx={{
                                color: "#777777",
                                ":hover": { color: "#179bb2" },
                                padding: "0px 20px",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: "300",
                              }}
                            >
                              {item.title}
                            </span>
                          </li>
                        );
                      }
                      return (
                        <li
                          key={item.id}
                          sx={{
                            marginBottom: "10px",
                            borderBottom: "1px solid #f2f2f2",
                          }}
                        >
                          <span
                            className={item.className || "menu-links"}
                            onClick={() => NavigateRouter(item)}
                            onKeyDown={() => NavigateRouter(item)}
                            tabIndex={0}
                            role="button"
                            id={item.id}
                            activeStyle={{
                              color: "#179bb2",
                            }}
                            sx={{
                              ...(!item.className && {
                                color: "#777777",
                                ":hover": { color: "#179bb2" },
                                padding: "0px 20px",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: "300",
                              }),
                              ...(item.className && {
                                padding: " 8px 15px",
                                borderRadius: "4px",
                                backgroundColor: "#288bd0",
                                color: "white",
                              }),
                            }}
                          >
                            {item.title}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        />
      </Box>
      <Seo title="Heartfulness Donations" />
      <section>
        <Box
          sx={{
            padding: isMobile ? "15px 20px" : "15px 70px",
            marginLeft: "auto",
            marginRight: "auto",
            letterSpacing: "0.3",
            color: "#777",
            fontSize: "18px",
          }}
        >
          <Flex
            sx={{
              flexDirection: ["column", null, null, "row", null],
              fontFamily: "opensans-light,sans-serif !important",
            }}
          >
            <Box sx={{ flex: ["2", null, null, "2", null] }}>
              <p>
                Welcome! Thank you for choosing to donate to Shri Ram Chandra
                Mission, India.
              </p>
              <p>
                Shri Ram Chandra Mission Foundation supports all the events and
                programs undertaken by Hearfulness.
              </p>
            </Box>
            <Box sx={{ flex: ["1", null, null, "1", null] }}>
              <img src={HFNlogo} alt="hfnlogo" width="300px" />
            </Box>
          </Flex>
          <p style={{ lineHeight: "34px" }}>
            On completion of the donation, the filled donation form will be
            generated and sent to you, which is to be printed, signed, scanned
            and sent to{" "}
            <b>
              <a
                href="mailto:info.accounts@srcm.org"
                style={{ color: "#4b77a1", textDecoration: "none" }}
              >
                {" "}
                info.accounts@srcm.org{" "}
              </a>
            </b>{" "}
            or can be couriered to the address mentioned in the form. Or you may
            fill and submit the form at your nearest center.
          </p>
          <div sx={{ border: "1px solid rgb(153, 187, 232)" }}>
            <p
              sx={{
                backgroundColor: "rgb(228, 237, 254)",
                borderBottom: "1px solid rgb(153, 187, 232)",
                color: "rgb(21, 66, 139)",
                fontWeight: "600",
                padding: "10px 20px",
              }}
            >
              Please note
            </p>
            <p style={{ padding: "0px 20px", lineHeight: "34px" }}>
              At this time, due to government regulations, citizens of India
              only can make online donations. Others can contact{" "}
              <a
                href="mailto:info.accounts@srcm.org"
                style={{ color: "#4b77a1", textDecoration: "none" }}
              >
                info.accounts@srcm.org
              </a>{" "}
              for making donations.
            </p>
          </div>
          <Card
            sx={{
              mt: "30px",
              bg: "#f5f5f5",
              border: "1px solid #e3e3e3",
              borderRadius: "4px",
              minHeight: "20px",
              padding: "19px",
            }}
          >
            <Box
              sx={{
                bg: "#47c6e126",
                border: "1px solid lightgray",
                mt: 0,
                margin: "0 20px 20px",
                padding: "20px",
              }}
            >
              {donationSrcm &&
                donationSrcm.map((data) => (
                  <div
                    key={data}
                    sx={{
                      borderBottom: "1px solid #c6c6c6",
                      pt: "10px",
                      pb: "10px",
                    }}
                  >
                    <b>{data.title}</b>
                    <p>{data.desc}</p>
                    {renderDonateBtn(data.donationId, data.modalTitle)}
                  </div>
                ))}
            </Box>
            <p sx={{ marginLeft: "20px" }}>
              There are no refunds for donations.
            </p>
          </Card>
        </Box>
      </section>
      <div
        sx={{
          padding: "0px",
          background: "#e8f2f9",
          height: "73px",
          bottom: "0",
          width: "100%",
        }}
      >
        <div
          sx={{
            padding: "10px 0px",
            background: "#e8f2f9",
          }}
        >
          <div
            sx={{
              marginTop: "19px",
              color: "#43679c",
              fontSize: "16px",
            }}
          >
            <p
              sx={{
                lineHeight: "24px",
                fontSize: "16px",
                fontWeight: "300",
                textAlign: "center",
              }}
            >
              {" "}
              © Copyright 2023 Shri Ram Chandra Mission, All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrmInIframe;
